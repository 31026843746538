<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column type="selection" width="55"></el-table-column>

      <el-table-column
        :label="fields.name.label"
        :prop="fields.name.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'name')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.project.label"
        :prop="fields.project.name"
      >
        <template slot-scope="scope">
          <app-list-item-relation-to-many
            :label="fields.project.label"
            :readPermission="fields.project.readPermission"
            :navigatePermission="fields.project.navigatePermission"
            :url="fields.project.viewUrl"
            :value="presenter(scope.row, 'project')"
          ></app-list-item-relation-to-many>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.comment.label"
        :prop="fields.comment.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'comment')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.status.label"
        :prop="fields.status.name"
        sortable="custom"
        width="150"
      >
        <template v-slot="scope">
          <el-tag
            :type="getStatusTagType(scope.row.status)"
            v-if="isStatusNull(scope.row.status)"
            >{{ presenter(scope.row, 'status') }}</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        width="200"
        v-if="hasPermissionToEdit"
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <el-button
              :disabled="isApprovedBtnDisabled(scope.row.status)"
              @click="doApproveProposal(scope.row)"
              plain
              size="mini"
              type="success"
            >
              <app-i18n code="common.approve"></app-i18n>
            </el-button>

            <el-button
              :disabled="isReviseBtnDisabled(scope.row.status)"
              @click="doOpenModal(scope.row)"
              plain
              size="mini"
              type="danger"
            >
              <app-i18n code="common.revise"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        width="130"
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <router-link :to="`/subject-area/${scope.row.id}`">
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>

            <router-link
              :to="`/subject-area/${scope.row.id}/edit`"
              v-if="hasPermissionToEdit"
            >
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>

            <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="hasPermissionToDestroy"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
    <el-dialog
      title="Comment"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <app-subject-area-comment-dialog
        :modal="true"
        :record="record"
        @cancel="doCancel"
        @submitRevise="onModalSuccess"
      />
    </el-dialog>
  </div>
</template>

<script>
import { SubjectAreaModel } from '@/modules/subject-area/subject-area-model';
import { mapGetters, mapActions } from 'vuex';
import { SubjectAreaPermissions } from '@/modules/subject-area/subject-area-permissions';
import { i18n } from '@/i18n';
import SubjectAreaCommentDialog from '@/modules/subject-area/components/subject-area-comment-dialog';
import SubjectAreaStatus from '@/shared/enums/subjectAreaStatus';

const status = SubjectAreaStatus.values;
const { fields } = SubjectAreaModel;

export default {
  name: 'app-subject-area-list-table',

  components: {
    [SubjectAreaCommentDialog.name]: SubjectAreaCommentDialog,
  },

  data() {
    return {
      dialogVisible: false,
      record: null,
    };
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'subjectArea/list/rows',
      count: 'subjectArea/list/count',
      loading: 'subjectArea/list/loading',
      pagination: 'subjectArea/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'subjectArea/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new SubjectAreaPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new SubjectAreaPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'subjectArea/list/doChangeSort',
      doChangePaginationCurrentPage:
        'subjectArea/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'subjectArea/list/doChangePaginationPageSize',
      doMountTable: 'subjectArea/list/doMountTable',
      doDestroy: 'subjectArea/destroy/doDestroy',
      doUpdateStatus: 'subjectArea/list/doUpdateStatus',
    }),

    presenter(row, fieldName) {
      return SubjectAreaModel.presenter(row, fieldName);
    },

    isStatusNull(status) {
      return status === null ? false : true;
    },

    getStatusTagType(value) {
      if (value === status.approved) {
        return 'success';
      }
      if (value === status.proposed) {
        return 'primary';
      }
      if (value === status.revise) {
        return 'danger';
      }
    },

    isApprovedBtnDisabled(value) {
      return value === status.approved;
    },

    isReviseBtnDisabled(value) {
      return value === status.revise || value === status.approved;
    },

    async doApproveProposal(row) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        const { id, updatedAt } = row;
        const values = {
          status: 'approved',
          updatedAt,
        };

        return this.doUpdateStatus({ id, values });
      } catch (error) {
        // no
      }
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },

    doOpenModal(payload) {
      this.record = payload;
      this.dialogVisible = true;
    },

    async onModalSuccess(payload) {
      let { id, values } = payload;
      values.status = status.revise;

      this.dialogVisible = false;

      return this.doUpdateStatus({ id, values });
    },

    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
