<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.subjectArea.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.subjectArea.list.title"></app-i18n>
      </h1>

      <app-subject-area-list-toolbar></app-subject-area-list-toolbar>
      <app-subject-area-list-filter></app-subject-area-list-filter>
      <app-subject-area-list-table></app-subject-area-list-table>
    </div>
  </div>
</template>

<script>
import SubjectAreaListFilter from '@/modules/subject-area/components/subject-area-list-filter.vue';
import SubjectAreaListTable from '@/modules/subject-area/components/subject-area-list-table.vue';
import SubjectAreaListToolbar from '@/modules/subject-area/components/subject-area-list-toolbar.vue';

export default {
  name: 'app-subject-area-list-page',

  components: {
    [SubjectAreaListFilter.name]: SubjectAreaListFilter,
    [SubjectAreaListTable.name]: SubjectAreaListTable,
    [SubjectAreaListToolbar.name]: SubjectAreaListToolbar,
  },
};
</script>

<style>
</style>
